import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="about us">
    <StyledContainer>
      <Subtitle>About Us</Subtitle>
        <SectionTitle>Software <StyledAnd>&nbsp;+&nbsp;</StyledAnd> Finance</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Proprietary Trading</FeatureTitle>
          <FeatureText>
            Koratas invests and trades its own funds to deliver market leading returns.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>International</FeatureTitle>
          <FeatureText>
            In markets around the world, we work relentlessly to uncover and capture new opportunities.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Software</FeatureTitle>
          <FeatureText>
            We are a software-first company. The majority of our investment strategies are driven by algorithms.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Risk Management</FeatureTitle>
          <FeatureText>
            We seek to manage risk in order to capitalize on opportunities and improve our performance.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledAnd = styled.span`
  font-family: Baskerville, 'Goudy Old Style', Palatino, 'Book Antiqua', serif;
  font-style: italic;
  font-weight: normal;
`

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`
